import { service } from '@whiz-cart/ui-shared/service/service';
import endpoint from '../util/endpoint';

type label = 'IsSuspicious';

export default service(
    class {
        async getCustomer(value: string) {
            const encoded = encodeURIComponent(value);
            try {
                return await endpoint('storeManager.getCustomer', { guidOrHash: encoded }).get();
            } catch (e: any) {
                if (e.data?.status === 13001) return null;
                throw e;
            }
        }

        async getCustomerByGuidOrHash(value: string) {
            const encoded = encodeURIComponent(value);
            const found = await endpoint('storeManager.getCustomerByGuidOrHash', { searchString: encoded }).get();
            return found;
        }

        async getAvailableFlags() {
            const found = await endpoint('storeManager.getAvailableFlags').get();
            return found;
        }

        async getBaseCustomerSessionTracking(userGuid: string) {
            const customerGuid = encodeURIComponent(userGuid);
            const found = await endpoint('storeManager.getBaseCustomerSessionTracking', { customerGuid }).get({
                params: { page: '0', size: '10' },
            });
            return found;
        }

        async getDetailedCustomerSessionTracking(userGuid: string) {
            const customerGuid = encodeURIComponent(userGuid);
            const found = await endpoint('storeManager.getDetailedCustomerSessionTracking', { customerGuid }).get({
                params: { page: '0', size: '10' },
            });
            return found;
        }

        async setFlags(customerGuid: string, flag: string, enabled: boolean) {
            const result = await endpoint('storeManager.setFlags').post({
                customerGuid: customerGuid,
                flag: flag,
                enabled: enabled,
            });
            return result;
        }

        async getLabels(userGuid: string) {
            if (!userGuid) return null;
            return await endpoint('storeManager.getCustomerLabels', { guid: userGuid }).get();
        }

        async setCustomerLabel(userGuid: string, label: label) {
            const response = await endpoint('storeManager.setCustomerLabel').put({
                customerGuid: userGuid,
                label: label,
            });
            return response;
        }

        async removeCustomerMark(userGuid: string, label: label) {
            const response = await endpoint('storeManager.removeCustomerLabel').delete({
                customerGuid: userGuid,
                label: label,
            });
            return response;
        }

        async getCustomerHistory(userGuid: string) {
            const response = await endpoint('storeManager.getCustomerHistory', { guid: userGuid }).get();
            return response;
        }
    },
);
