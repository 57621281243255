import { ChevronRight, Close } from '@mui/icons-material';
import { Button, Collapse, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslator } from '@translate';
import { errorStackOnly, errorToString } from '@whiz-cart/node-shared/errorToString';
import _ from 'lodash';
import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import css from './useMessage.module.less';
import { CopyOnClick } from '@whiz-cart/ui-shared/copyOnClick/copyOnClick.component';

export type KnownError<T extends object = any> = [match: Record<string, unknown>, message: string | ((error: T) => string)];

export function matchError(error: unknown, knownErrors: KnownError[] = []) {
    if (error instanceof Object) {
        const data = (error as any).data;

        const knownError = knownErrors.find(([match]) => {
            return _.isMatch(error, match) || (data instanceof Object && _.isMatch(data, match));
        });

        if (knownError) {
            const message = knownError[1] instanceof Function ? knownError[1](error) : knownError[1];

            if (error instanceof Error) {
                const copy = new Error(message, { cause: error });
                copy.stack = error.stack;
                return copy;
            }

            return message;
        }
    }

    return error;
}

type Props = { error?: unknown; title?: string; close: () => void };

function SnackbarErrorContent({ title, error, close }: Props) {
    const [open, setOpen] = useState(false);

    if (!error) {
        return null;
    }

    return (
        <CopyOnClick value={error instanceof Error ? error.stack : errorToString(error)}>
            <Box
                sx={{
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: 'auto max-content max-content',

                    pre: {
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        maxHeight: '10rem',
                        overflow: 'auto',
                    },

                    'p:not(:first-of-type)': {
                        ml: 2,
                    },
                }}
            >
                <pre className={css.errorTitle}>
                    {(title ?? errorToString(error)).split('\n').map((line, index) => (
                        <p key={index}>{line}&nbsp;</p>
                    ))}
                </pre>

                {error instanceof Error && (
                    <Button
                        variant="text"
                        color="inherit"
                        startIcon={
                            <ChevronRight
                                sx={{
                                    transition: 'all 300ms',
                                    transform: `rotate3d(0, 0, 1, ${open ? '90deg' : '0'})`,
                                }}
                            />
                        }
                        onClick={() => setOpen(!open)}
                    >
                        Stack
                    </Button>
                )}
                <IconButton onClick={() => close()} style={{ color: 'white' }}>
                    <Close />
                </IconButton>
            </Box>

            {error instanceof Error && (
                <>
                    <Collapse in={open}>
                        <Box
                            sx={{
                                mt: 2,
                                display: 'grid',
                            }}
                        >
                            <Box
                                sx={{
                                    minWidth: 0,
                                    maxHeight: '20rem',
                                    fontFamily: 'monospace',
                                    whiteSpace: 'pre',
                                    background: '@lightGrayColor',
                                    overflow: 'auto',
                                }}
                            >
                                {title && errorToString(error)}
                                {errorStackOnly(error)}
                            </Box>
                        </Box>
                    </Collapse>
                </>
            )}
        </CopyOnClick>
    );
}

export function useShowError() {
    const { showMessage, closeMessage } = useShowMessage();

    function showError(error?: unknown | unknown[], knownErrors?: KnownError[], title?: string) {
        if (!error) {
            return;
        }
        const errors = Array.isArray(error) ? error : [error];
        const keys: SnackbarKey[] = [];
        errors.forEach((e: any) => {
            console.error(e);
            const key = showMessage(
                <SnackbarErrorContent
                    title={title}
                    error={matchError(e, knownErrors)}
                    close={() => {
                        closeMessage(key);
                    }}
                />,
                { variant: 'error' },
            );
            keys.push(key);
        });
        return keys;
    }

    return { showError, closeError: closeMessage };
}

export function useShowMessage() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function closeMessage(key?: SnackbarKey) {
        closeSnackbar(key);
    }

    function showMessage(content: ReactNode, options?: OptionsObject | undefined) {
        const key = `key-${Math.random().toString(16).slice(2)}`;
        return enqueueSnackbar(content, {
            persist: true,
            key,
            ...options,
        });
    }

    return { showMessage, closeMessage };
}

export function useShowSuccess() {
    const { showMessage, closeMessage } = useShowMessage();
    const t = useTranslator();

    function showSuccess({ message = t('success') }: { message?: ReactNode | undefined } = {}) {
        if (!message) {
            return;
        }
        const key = `key-${Math.random().toString(16).slice(2)}`;
        return showMessage(
            <>
                <CheckCircleIcon className={css.checkIcon} />
                {message}
            </>,
            {
                variant: 'success',
                className: css.successMessage,
                autoHideDuration: 3000,
                persist: false,
                key,
                action: (
                    <IconButton onClick={() => closeMessage(key)} style={{ color: 'white' }}>
                        <Close />
                    </IconButton>
                ),
            },
        );
    }

    return { showSuccess, closeSuccess: closeMessage };
}

export function useErrorMessage(error?: unknown, knownErrors?: KnownError[], title?: string) {
    const { showError, closeError } = useShowError();

    useEffect(() => {
        const errorKey = showError(error, knownErrors, title);
        return () => {
            errorKey?.forEach((k) => k && closeError(k));
        };
    }, [error]);
}

export function useCloseOnExit(closeError: () => void) {
    useEffect(() => {
        return () => {
            closeError();
        };
    }, []);
}
