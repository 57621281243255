import { KnownError, useErrorMessage } from '@error/useMessage';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { MaybePromise } from '@whiz-cart/node-shared/maybePromise';
import { useProcessing } from '@whiz-cart/ui-shared/hooks/useProcessing';
import { createStore } from 'cross-state';
import React, { Ref, forwardRef } from 'react';

export interface ActionButtonProps extends LoadingButtonProps {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => MaybePromise<unknown>;
    knownErrors?: KnownError[];
}

const actionButtonInProgress = createStore(false);

export const ActionButton = forwardRef(function ActionButton(
    { onClick, knownErrors, ...buttonProps }: ActionButtonProps,
    ref: Ref<HTMLButtonElement>,
) {
    const someButtonInProgress = actionButtonInProgress.useStore();

    const [execute, inProgress, error] = useProcessing(async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (actionButtonInProgress.get()) {
            return;
        }

        actionButtonInProgress.set(true);
        try {
            await onClick(e);
        } finally {
            actionButtonInProgress.set(false);
        }
    });
    useErrorMessage(error, knownErrors);

    return (
        <LoadingButton
            ref={ref}
            loading={inProgress}
            onClick={execute}
            {...buttonProps}
            disabled={someButtonInProgress || buttonProps.disabled}
        />
    );
});
