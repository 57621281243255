// @ts-nocheck
import { faBars, faChevronLeft, faEllipsisV, faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, Tooltip } from '@mui/material';
import { ClickOutside } from '@whiz-cart/ui-shared/clickOutside/clickOutside.component';
import { Input } from '@whiz-cart/ui-shared/form/input.component';
import { NavLink } from '@whiz-cart/ui-shared/routing/navLink.component';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { UrlState } from '@whiz-cart/ui-shared/url/url.action';
import c from 'classnames';
import _ from 'lodash';
import React, { isValidElement, useEffect, useState, ReactNode, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavigation as toggleNavigationAction } from '../navigation/navigation.action';
import { t } from '../translate';
import { DirectSearch, isDirectSearch, isProvidedSearch, ProvidedSearch } from './titleBar';
import css from './titleBar.module.less';
import { ActionConfig } from './action';
import { ActionLoadingIcon } from './useActionLoadingIndicator';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CustomerMark from '@util/CustomerMark';
import { ActionButton } from '@components/actionButton';
import customerService from '@customer/customer.service';
import storeService from '@store/store.service';

export type TitleBarProps = {
    back: string | Record<string, any> | Parameters<(typeof urlService)['calcUrl']> | ((e: React.MouseEvent) => void);
    autoBack?: boolean | { exclude: string | RegExp | (string | RegExp)[] | ((path: string) => boolean) };
    title: string;
    colorBar: string;
    breakActionsEarly: boolean;
    collapse: boolean;
    guid: string;
    search?: ProvidedSearch | DirectSearch;
    visibleActions?: number;
    onlyKeepActionsWithLinkId?: string;
};

export default function TitleBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [customerMarkOpen, setCustomerMarkOpen] = useState<boolean>(false);

    const config = useSelector((state: any) => state.config);
    const envColor = config.showEnvColor;
    const urlHistory = useSelector((state: any) => state.url.history as UrlState[]);
    const query = useSelector((state: any) => {
        return isProvidedSearch(state.titleBar.current.search)
            ? (state.url.path.replace(new RegExp(`${_.escapeRegExp(state.titleBar.current.search.basePath)}/?`), '') as string)
            : isDirectSearch(state.titleBar.current.search)
              ? (state.titleBar.current.search.value as string)
              : undefined;
    });

    const currentTitleBar: TitleBarProps = useSelector((state: any) => state.titleBar.current);
    const {
        autoBack = false,
        back,
        title,
        colorBar = 'transparent',
        breakActionsEarly,
        collapse,
        guid,
        search,
        extraComponent,
        isCustomerMarked,
    } = currentTitleBar;
    const visibleActions = currentTitleBar.visibleActions ?? config.titleBarVisibleActions;

    const [searchFocused, setSearchFocused] = useState(!!query);
    let titleComponent = useSelector((state: any) => state.titleBar.current.titleComponent as ReactNode);

    const dispatch = useDispatch();

    let actions = useSelector((state: any) => state.titleBar.actions as ActionConfig[]);

    const backIsActive = !!autoBack || back;
    const backUrl = getAdjustedBack();

    const storeGuid = useSelector((state) => state.url.params.storeGuid);
    const enableCustomerMark = storeService.getConfig.useAction(storeGuid)?.[0]?.enableCustomerMark === 'true';

    useEffect(() => {
        setMenuOpen(false);
        setSearchFocused(false);
    }, [guid]);

    function openMenu() {
        setMenuOpen(true);
    }

    function closeMenu() {
        if (menuOpen) setMenuOpen(false);
    }

    function filterActionsForTitlebar(actions: any[], titleBar: TitleBarProps) {
        if (titleBar.onlyKeepActionsWithLinkId) {
            return actions.filter((a) => a?.titleBarLinkId === titleBar.onlyKeepActionsWithLinkId);
        } else {
            return actions;
        }
    }

    /**
     * returns found url, execute function gets index of url in urlHistory
     */
    function getParentUrl(): [url?: UrlState, index?: number] {
        const [first] = urlHistory;

        if (!autoBack || !first) {
            return [];
        }

        const index = urlHistory.findIndex((url) => {
            const pattern = autoBack === true ? first.path : autoBack.exclude;

            if (pattern instanceof Function) {
                return !pattern(url.path);
            }

            return !_.castArray(pattern).some((pattern) => {
                if (typeof pattern === 'string') {
                    pattern = new RegExp(`^${pattern.replace(/\/$/, '')}(/|$)`);
                }
                return pattern.test(url.path) || url.path == first.path;
            });
        });

        const url = urlHistory[index];
        return [url, index === -1 ? undefined : index];
    }

    /**
     * Returns parent path of the current url if autoBack is enabled, otherwise returns back.
     * @returns path to parent url
     */
    function getAdjustedBack() {
        if (autoBack) {
            const [url] = getParentUrl();
            if (url) {
                return [url.path, url.params, url.hash];
            }
        }
        return back;
    }

    function handleBackClick(e: MouseEvent<HTMLAnchorElement>) {
        if (back instanceof Function) {
            back(e);
            e.preventDefault();
            return;
        }

        const [, index] = getParentUrl();
        if (index !== undefined) {
            urlService.removeFromBackHistory(index);
        }
    }

    function toggleNavigation(event: React.MouseEvent<SVGSVGElement>) {
        event.nativeEvent.stopImmediatePropagation();
        dispatch(toggleNavigationAction(undefined));
    }

    function renderAction({ icon, label, action, className, id, isLoading }: ActionConfig, inMenu?: boolean) {
        const loadingIcon = <ActionLoadingIcon />;
        const regularIcon = isValidElement(icon) ? icon : icon ? <FontAwesomeIcon fixedWidth icon={icon as IconProp} /> : null;
        const common = {
            'data-testid': `titleBarElement-${id}`,
            className: c(css.action, className),
            children: (
                <>
                    {isLoading ? loadingIcon : regularIcon}
                    {inMenu && label}
                </>
            ),
        };

        return (
            <Tooltip key={id} title={inMenu ? '' : label || ''} enterDelay={1000} enterNextDelay={1000}>
                {typeof action === 'string' ? (
                    <span>
                        <NavLink {...common} to={action} />
                    </span>
                ) : (
                    <div {...common} onClick={action instanceof Function ? action : undefined} />
                )}
            </Tooltip>
        );
    }

    if (search) {
        const isOpen = query || searchFocused;

        if (isOpen) {
            titleComponent = (
                <ClickOutside className={css.search} onClickOutside={() => setSearchFocused(false)}>
                    <Input
                        className={css.input}
                        autoFocus
                        {...search}
                        value={query ?? ''}
                        onChange={(value) => {
                            setSearchFocused(true);
                            if (isProvidedSearch(search)) {
                                const encodedValue = encodeURIComponent(value);
                                urlService.pushUrl(`${search.basePath}/${encodedValue}`);
                            } else if (isDirectSearch(search)) {
                                search.onChange(value);
                            } else {
                                console.warn('Missing onchange on search');
                            }
                        }}
                    />
                </ClickOutside>
            );
        }

        const searchAction = !isOpen
            ? {
                  id: '#search',
                  index: -1,
                  icon: faSearch,
                  label: t('search'),
                  action: () => setTimeout(() => setSearchFocused(true)),
              }
            : {
                  id: '#search',
                  index: -1,
                  icon: faTimes,
                  label: t('close'),
                  action: () => {
                      if (isProvidedSearch(search)) {
                          urlService.pushUrl(search.basePath);
                      } else if (search.onChange) {
                          search.onChange('');
                      }
                  },
                  className: c({ [css.searchIconClear]: actions.length > 0 }),
              };

        actions = [searchAction, ...actions];
    }

    actions = filterActionsForTitlebar(actions, currentTitleBar);
    const allActions = _.orderBy(actions, ['index', 'name', 'id']);
    const actionsTop =
        allActions.length <= (breakActionsEarly ? visibleActions - 1 : visibleActions)
            ? allActions
            : [...allActions.slice(0, Math.max(0, visibleActions - 1)), { id: '#overflow', icon: faEllipsisV, action: openMenu }];
    const actionsMenu =
        actions.length <= (breakActionsEarly ? visibleActions - 1 : visibleActions) ? undefined : allActions.slice(visibleActions - 1);

    const getUserGuid = () => {
        let uGuid = null;
        actionsMenu?.forEach((action) => {
            if (action?.id?.includes('customerMark')) {
                uGuid = action?.userGuid;
            }
        });
        return uGuid;
    };

    const markCustomer = async () => {
        await customerService.setCustomerLabel(getUserGuid(), 'IsSuspicious').then(() => {
            if (typeof customerMark === 'string') {
                const url = urlService.getUrl()?.path.split('/');
                const urlPath = url?.slice(0, url.length).join('/');
                urlService.pushUrl('/session');
                urlService.pushUrl(urlPath);
            }
        });
    };

    const removeMarkFromCustomer = async () => {
        await customerService.removeCustomerMark(getUserGuid(), 'IsSuspicious').then(() => {});
    };

    return (
        <div
            data-testid="titleBarContainer"
            className={c(css.titleBar, { [css.collapsed]: collapse })}
            style={{ borderTopColor: colorBar, backgroundColor: envColor && envColor }}
        >
            {backIsActive && (
                <NavLink data-testid="navigateBack" to={backUrl} onClick={handleBackClick}>
                    <FontAwesomeIcon icon={faChevronLeft} className={css.leftButton} />
                </NavLink>
            )}

            {!backIsActive && <FontAwesomeIcon icon={faBars} className={css.leftButton} onClick={toggleNavigation} />}

            {titleComponent || (
                <div className={css.titleComponent} style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '32px' }}>
                    {title}
                    {enableCustomerMark && isCustomerMarked && extraComponent && (
                        <div
                            onClick={() => {
                                isCustomerMarked ? removeMarkFromCustomer() : setCustomerMarkOpen(true);
                            }}
                        >
                            {' '}
                            <CustomerMark size={1.5} />
                        </div>
                    )}
                </div>
            )}

            {extraComponent}

            {enableCustomerMark && isCustomerMarked && titleComponent && extraComponent && (
                <div
                    onClick={() => {
                        isCustomerMarked ? removeMarkFromCustomer() : setCustomerMarkOpen(true);
                    }}
                >
                    {' '}
                    <CustomerMark size={1.5} />
                </div>
            )}

            <ClickOutside data-testid="titleBarActions" className={css.actions} onClick={closeMenu} onClickOutside={closeMenu}>
                {actionsTop.map((action) => renderAction(action))}
                {menuOpen && actionsMenu && (
                    <div data-testid="titleBarMenu" className={css.menu}>
                        {actionsMenu.map((action) => (
                            <React.Fragment key={action?.id}>
                                {!action.id?.includes('customerMark') ? (
                                    <>{renderAction(action, true)}</>
                                ) : (
                                    <>
                                        {enableCustomerMark && (
                                            <div
                                                className={css.action}
                                                onClick={() => {
                                                    isCustomerMarked ? removeMarkFromCustomer() : setCustomerMarkOpen(true);
                                                }}
                                            >
                                                <CustomerMark animate={false} unmarked={isCustomerMarked} size={1.3} />
                                                <span>
                                                    {isCustomerMarked
                                                        ? t('sessionMonitor.removeCustomerMark')
                                                        : t('sessionMonitor.customerMark')}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </ClickOutside>

            <Dialog open={customerMarkOpen} onClose={() => setCustomerMarkOpen(false)}>
                <div className={css.customerMarkConfirmationContainer}>
                    <h3>{t('sessionMonitor.confirmCustomerMark')}</h3>
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <Button variant="outlined" onClick={() => setCustomerMarkOpen(false)}>
                            {t('cancel')}
                        </Button>
                        <ActionButton
                            onClick={() => {
                                markCustomer();
                                setCustomerMarkOpen(false);
                            }}
                        >
                            {t('ok')}
                        </ActionButton>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
